export const getWeddingRingProducts = async () => {
  try {
    const api_url = process.env.API_URL;
    const res = await fetch(
      `${api_url}api/products?SortBy=product_sold_count&SortType=desc&limit=10&product_categories=["wedding ring"]`
    );
    const products = await res.json();
    return {
      title: "Wedding Ring",
      url_path: "/product-category/wedding",
      products,
    };
  } catch (error) {
    return {
      title: "Wedding Ring",
      url_path: "/product-category/wedding",
      products: [],
    };
  }
};
