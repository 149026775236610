import querystring from "querystring";

export const getNewArrivalProducts = async () => {
  try {
    const api_url = process.env.API_URL;
    const filterQueries = {};

    const excludeCat = ["gift card"];
    filterQueries.excludeCat = excludeCat;

    const filterQueryString = querystring.stringify(filterQueries);

    const res = await fetch(
      `${api_url}api/products?SortBy=createdAt&SortType=desc&limit=10&product_is_new=true&${filterQueryString}`
    );
    const products = await res.json();
    return {
      title: "New Arrivals",
      url_path: "/whats-new",
      products,
    };
  } catch (error) {
    return {
      title: "New Arrivals",
      url_path: "/whats-new",
      products: [],
    };
  }
};
