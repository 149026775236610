export const getAttributes = async () => {
  try {
    const api_url = `${process.env.API_URL}api/product-attributes`;
    const res = await fetch(api_url);
    const attributes = await res.json();

    if (res.status !== 200) {
      return { attributes: [] };
    }
    return { attributes };
  } catch (error) {
    return { attributes: [] };
  }
};
