const getListArticles = async () => {
  try {
    const api_url = process.env.API_URL;
    const response = await fetch(
      `${api_url}api/article/list?per_page=5&status=publish&sortBy=post_date`
    );

    if (response.ok) {
      const { data } = await response.json();
      return data;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getArticles = async () => {
  const articles = await getListArticles();
  const listArticles = {
    title: "The Articles",
    url_path: "/magazine",
    articles,
  };
  return listArticles;
};

export const getDataArticles = async (limit) => {
  try {
    const api_url = `${process.env.API_URL}api/article/magz?&limit=${limit}`;
    const res = await fetch(api_url);
    const articles = await res.json();
    if (res.status !== 200) {
      return { articles: [], fetch_url: api_url };
    }
    return { articles, fetch_url: api_url };
  } catch (error) {
    return { articles: [], fetch_url: "" };
  }
};
