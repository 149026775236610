export const getFlashSaleProducts = async () => {
  try {
    const api_url = process.env.API_URL;
    const res = await fetch(
      `${api_url}api/product-promotions/list-active?limit=10`
    );
    const products = await res.json();
    return {
      title: "Flash Sale",
      url_path: "/flash-sale",
      products,
    };
  } catch (error) {
    return {
      title: "Flash Sale",
      url_path: "/flash-sale",
      products: [],
    };
  }
};
