import querystring from "querystring";

export const getBestSellerProducts = async () => {
  try {
    const filterQueries = {};

    const excludeCat = ["gift card"];
    filterQueries.excludeCat = excludeCat;
    const filterQueryString = querystring.stringify(filterQueries);

    const api_url = process.env.API_URL;
    const res = await fetch(
      `${api_url}api/products?SortBy=product_sold_count&SortType=desc&limit=10&${filterQueryString}`
    );
    const products = await res.json();
    return {
      title: "Best Seller",
      url_path: "/all-jewelries",
      products,
    };
  } catch (error) {
    return {
      title: "Best Seller",
      url_path: "/all-jewelries",
      products: [],
    };
  }
};
