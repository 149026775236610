import { Breakpoint } from "../../utils/media-query";
import styles from "./banner-skeleton.module.css";

const BannerSkeleton = () => {
  const [isDesktop, isTablet, isMobile] = Breakpoint();

  if (isMobile) {
    return (
      <>
        <div>
          <div className="container-mobile">
            <div className={styles.icon_section}>
              <div>
                <div className={styles.icon_image}></div>
                <div className={styles.title}></div>
              </div>
              <div>
                <div className={styles.icon_image}></div>
                <div className={styles.title}></div>
              </div>
              <div>
                <div className={styles.icon_image}></div>
                <div className={styles.title}></div>
              </div>
              <div>
                <div className={styles.icon_image}></div>
                <div className={styles.title}></div>
              </div>
              <div>
                <div className={styles.icon_image}></div>
                <div className={styles.title}></div>
              </div>
            </div>
          </div>
          <div className={styles.image_container}></div>
        </div>
      </>
    );
  }
  return <div className={styles.banner}></div>;
};
export default BannerSkeleton;
